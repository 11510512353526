import React from "react";
import SEO from "../../components/SEO";
import Layout from "../../layouts/index";
import ContactForm from "../../components/ContactForm";
import Map from '../../components/Map'

const Contact = (props) => {
  const path = props.uri;

  return (
    <Layout bodyClass="page-contact" path={path}>
      <SEO title="Contact" />
      <div className="contact-section">
        <div className="container pt-10 pb-5 pt-md-15 pb-md-5 form-wrap">
          <div className="row justify-content-center">
            <div className="col-12">
              <h2 className="text-center title-3 mb-4">Contact Us</h2>
            </div>
            <div className="col-10 mb-md-2">
              <ContactForm />
            </div>
          </div>
        </div>
        <div className="map-wrap">
          <Map/>  
        </div>
      </div>
    </Layout>
  );
};

export default Contact;
